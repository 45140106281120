<template>
  <v-row v-scroll="onScroll">
    <!-- header -->
    <mini-header title="Resumen del Documento" :breadcrumbs="breadcrumbs" :breadcrumbsItemDinamic="{ loading: loadingDetail, text: (activeDocument || {}).name}" :dense="true" :hiddenTab="true" :addPadding="true" :scroll="scroll">
      <template v-slot:main>
        <v-menu v-if="$vuetify.breakpoint.width <= 1920" close-on-click close-on-content-click offset-y left :nudge-bottom="10" transition="slide-y-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="mr-2" outlined v-bind="attrs" v-on="on">
              <v-icon left>mdi-plus-box </v-icon>Opciones<v-icon size="18" right>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list class="pa-0">
            <v-list-item class="px-3" @click="downloadFile('documents', `${instance.document_type}_${number}`)" :ripple="false">
              <v-list-item-title class="body-2">Descargar XML</v-list-item-title>
            </v-list-item>
            <v-list-item class="px-3"  v-if="['CL52', 'PE31', 'PE09'].includes((instance || {}).document_type || '')" @click="dialogMarkAsAnulled=true" outlined :disabled="!instance.status || ((instance || {}).status || {}).code === 'NUL'" :ripple="false">
              <v-list-item-title class="body-2">Marcar como nulo</v-list-item-title>
            </v-list-item>
            <v-list-item class="px-3" v-if="$helpers.hasPermission(['add_grant']) && ['CL33', 'CL34'].includes((instance || {}).document_type || '')" @click="dialogGrant=true" :ripple="false">
              <v-list-item-title class="body-2">Ceder documento</v-list-item-title>
            </v-list-item>
            <v-list-item class="px-3" @click="modalEmail=true" :ripple="false">
              <v-list-item-title class="body-2">Enviar por mail</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <template v-else>
          <v-btn class="mr-2" v-if="['CL52', 'PE31', 'PE09'].includes((instance || {}).document_type || '')" @click="dialogMarkAsAnulled=true" outlined :disabled="!instance.status || ((instance || {}).status || {}).code === 'NUL'">
            <v-icon left>mdi-file-document-remove</v-icon>
            Marcar como nulo
        </v-btn>
        <v-btn @click="downloadFile('documents', `${instance.document_type}_${number}`)" :disabled="loadingDetail" outlined><v-icon left>mdi-download</v-icon>Descargar XML</v-btn>
        <v-btn class="mx-2" @click="modalEmail=true" outlined><v-icon left>mdi-email</v-icon>Enviar por mail</v-btn>
        <v-btn class="mr-2" outlined v-if="$helpers.hasPermission(['add_grant']) && ['CL33', 'CL34'].includes((instance || {}).document_type || '')" @click="dialogGrant=true" :disabled="loadingDetail"><v-icon left>mdi-file-move-outline</v-icon>Ceder documento</v-btn>
        </template>
        <v-btn @click="modalPDF=true" outlined><v-icon left>mdi-text-box</v-icon>Ver PDF</v-btn>
        <template v-if="($store.state.auth.account.country !== 'PE' && instance.document_type !== 'CL43')">
          <v-menu v-if="!!documentsCanBeCreated?.length || !!checkCreatePermissionDTE(instance.document_type) || (instance.document_type === 'CL52' && !!checkCreatePermissionDTE('CL33'))" close-on-click close-on-content-click offset-y left :nudge-bottom="10" transition="slide-y-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="ml-2" color="primary" v-bind="attrs" v-on="on" :disabled="loadingDetail">
                <v-icon left>mdi-file-plus</v-icon>Crear documento<v-icon size="18" right>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list class="pa-0">
              <v-list-item class="px-3" v-if="!!checkCreatePermissionDTE(instance.document_type) && !['CL61',  'CL56', 'CL112', 'CL111'].includes(instance?.document_type)" :ripple="false" :to="{ name: 'DocumentsClone', params: { id: instance.id }, query: { document_type: instance.document_type, action: 'invoice-guide' } }">
                <v-list-item-title class="body-2">Duplicar</v-list-item-title>
              </v-list-item>
              <v-list-item class="px-3" v-if="instance.document_type === 'CL52' && !!checkCreatePermissionDTE('CL33')" :ripple="false" :to="{ name: 'DocumentsClone', params: { id: instance.id }, query: { document_type: 'CL33' } }">
                <v-list-item-title class="body-2">Facturar guía de despacho</v-list-item-title>
              </v-list-item>
              <div v-if="documentsCanBeCreatedList[instance.document_type]">
                <v-list-item class="px-3" v-for="(item, index) in documentsCanBeCreated" :key="index" :ripple="false" @click="(['CL56','CL61', 'CL112', 'CL111'].includes(item)) ? handlerClone(item) : $router.push({ name: 'DocumentsClone', params: { id: instance.id }, query: { document_type: item } })">
                  <v-list-item-title class="body-2">{{ DocumentTypesList.find((d) => (d || {}).id === item).name }} </v-list-item-title>
                </v-list-item>
              </div>
            </v-list>
          </v-menu>
        </template>
      </template>
    </mini-header>
    <!-- end header -->

    <v-col class="px-0" style="z-index: 0;">
      <skeleton-detail v-if="loadingDetail" />
      <div class="d-flex" v-else>
        <!-- detail card -->
        <div class="mr-6" style="min-width: 400px;">
          <div style="position:fixed!important;">
            <v-img contain :width="400" :height="110" :src="require(`@/assets/backgrounds/background-document-detail.svg`)">
              <span class="px-4 pt-4 subtitle-2 white--text font-weight-bold d-inline-block text-truncate" style="max-width: 300px;">
                {{((instance || {}).document_type || '') | nameDocumentsType}} Nº{{number}}
              </span>
              <v-row class="ml-2 mt-3" align="end" no-gutters>
                <v-col cols="5" class="py-0 px-2">
                  <span class="d-block body-1 white--text">Total</span>
                  <span class="subtitle-2 white--text">
                    <span class="subtitle-2 white--text">
                      <span class="font-weight-bold">{{(instance.total) | currency((instance || {}).document_type || '')}}</span>
                      <span class="caption">
                        <template v-if="Object.keys(((instance || {}).exchange || {})).length">{{instance.exchange.currency_from || instance.exchange.currency_to || instance.exchange.currency}}</template>
                        <template v-else>{{((instance || {}).document_type || '') | firstDocumentsTypeCurrency}}</template>
                      </span>
                    </span>
                  </span>
                </v-col>
                <v-col cols="5" class="py-0 px-2">
                  <span class="d-block body-1 white--text">Fecha</span>
                  <span class="subtitle-2 white--text">
                    <span class="font-weight-bold">{{instance.date | date}}</span>
                  </span>
                </v-col>
              </v-row>
            </v-img>
            <v-card class="pa-0 mt-4" width="400" transition="slide-y-transition" min-height="calc(100% - 278px)" flat style="position:fixed!important;">
            <v-toolbar color="transparent" flat height="46">
              <v-toolbar-title class="px-5 subtitle-2 fontBody--text font-weight-bold">Resumen del documento</v-toolbar-title>
            </v-toolbar>
            <v-card-text class="overflow-y-auto pa-0" :style="$vuetify.breakpoint.height <= 700 ? 'max-height: calc(100vh - 353px)' : 'max-height: calc(100vh - 300px)'">
              <v-divider />
              <!-- detail -->
              <div class="py-4 px-5">
                <v-list class="v-list-form-main transparent pa-0">
                  <v-list-item class="pa-0">
                    <v-list-item-title class="fontBody--text subtitle-2">Razón Social</v-list-item-title>
                    <v-list-item-subtitle class="body-1">
                      <template v-if="((instance || {}).customer || {}).name || ((instance || {}).taxpayer || {}).name">{{((instance || {}).customer || {}).name || ((instance || {}).taxpayer || {}).name}}</template>
                      <span class="body-2 text--disabled font-italic" v-else>Sin especificar</span>
                    </v-list-item-subtitle>
                  </v-list-item>
                  <v-list-item class="pa-0">
                    <v-list-item-title class="fontBody--text subtitle-2">{{$t('generals.RUT')}}</v-list-item-title>
                    <v-list-item-subtitle class="body-1">{{ (((instance || {}).customer || {}).taxpayer || {}).tax_id || ((instance || {}).taxpayer || {}).tax_id}}</v-list-item-subtitle>
                  </v-list-item>
                  <v-list-item class="pa-0">
                    <v-list-item-title class="fontBody--text subtitle-2">Tipo de documento</v-list-item-title>
                    <v-list-item-subtitle class="body-1">{{((instance || {}).document_type || '') | nameDocumentsType}}</v-list-item-subtitle>
                  </v-list-item>
                  <v-list-item class="pa-0" v-if="instance.status !== 'draft'">
                    <v-list-item-title class="fontBody--text subtitle-2">{{$t('generals.Folio')}}</v-list-item-title>
                    <v-list-item-subtitle class="body-1">{{number}}</v-list-item-subtitle>
                  </v-list-item>
                  <v-list-item class="pa-0">
                    <v-list-item-title class="fontBody--text subtitle-2">ID</v-list-item-title>
                    <v-list-item-subtitle class="body-1">
                      <v-tooltip color="bgSearch" right transition="scale-transition" max-width="300">
                        <template v-slot:activator="{on}">
                          <v-btn class="fontDraw--text" @click="toClipboard(instance.id)" text v-on="on" :ripple="false">{{instance.id}}</v-btn>
                        </template>
                        <span class="d-block px-3 py-2">Copiar</span>
                      </v-tooltip>
                    </v-list-item-subtitle>
                  </v-list-item>
                  <v-list-item class="pa-0">
                    <v-list-item-title class="fontBody--text subtitle-2">Creado el</v-list-item-title>
                    <v-list-item-subtitle class="body-1">{{instance.created | dateTimeSecond}}</v-list-item-subtitle>
                  </v-list-item>
                  <v-list-item class="pa-0" v-if="instance.imported">
                    <v-list-item-title class="fontBody--text subtitle-2">Documento</v-list-item-title>
                    <v-list-item-subtitle class="body-1">
                      <v-chip color="lightBlue" small label>
                        Histórico
                        <v-icon right>mdi-file-clock</v-icon>
                      </v-chip>
                    </v-list-item-subtitle>
                  </v-list-item>
                </v-list>
              </div>
              <!-- end detail -->

              <!-- totals -->
              <v-col class="px-5">
                <v-row no-gutters align="center" justify="space-between">
                  <v-col class="pa-0">
                    <span class="ml-n1 subtitle-2 secondary--text font-weight-bold"><v-icon size="18" left color="secondary">mdi-currency-usd</v-icon>Totales</span>
                  </v-col>
                </v-row>
                <v-divider class="my-3" />
                <v-list class="transparent pa-0" width="100%">
                  <v-list-item>
                    <v-list-item-title class="subtitle-2">Subtotal</v-list-item-title>
                    <v-list-item-subtitle class="text-right font-weight-bold">{{instance.subtotal | currency(((instance || {}).document_type || ''))}}</v-list-item-subtitle>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title class="subtitle-2">Descuento</v-list-item-title>
                    <v-list-item-subtitle class="text-right fontBody--text font-weight-bold">
                      <template v-if="instance.discount">
                        {{instance.discount | number}}
                        <template v-if="instance.discount_scheme === '%'">{{instance.discount_scheme}}</template>
                        <template v-if="instance.discount_scheme === '$'">{{((instance || {}).document_type || '') | firstDocumentsTypeCurrency}}</template>
                      </template>
                      <template v-else>-</template>
                    </v-list-item-subtitle>
                  </v-list-item>
                  <template v-if="(activeDocument || {}).has_taxes_detail">
                    <v-list-item class="mt-3">
                      <v-list-item-title class="subtitle-2">Exento</v-list-item-title>
                      <v-list-item-subtitle class="text-right fontBody--text font-weight-bold">{{instance.exempt | currency(((instance || {}).document_type || ''))}}</v-list-item-subtitle>
                    </v-list-item>
                    <template v-if="(activeDocument || {}).has_taxes">
                      <v-list-item>
                        <v-list-item-title class="subtitle-2">Neto</v-list-item-title>
                        <v-list-item-subtitle class="text-right fontBody--text font-weight-bold">{{instance.amount | currency(((instance || {}).document_type || ''))}}</v-list-item-subtitle>
                      </v-list-item>
                      <v-list-item class="mt-3">
                        <v-list-item-title class="subtitle-2">Impuestos ({{activeDocument.tax}}%)</v-list-item-title>
                        <v-list-item-subtitle class="text-right fontBody--text font-weight-bold">{{instance.tax | currency(((instance || {}).document_type || ''))}}</v-list-item-subtitle>
                      </v-list-item>
                    </template>
                  </template>
                  <v-list-item class="mt-3">
                    <v-list-item-title class="subtitle-2 fontBody--text font-weight-bold subtitle-2">Total documento</v-list-item-title>
                    <v-list-item-subtitle class="text-right subtitle-2 fontBody--text font-weight-bold">{{instance.total | currency(((instance || {}).document_type || ''))}}
                      <template v-if="Object.keys(instance.exchange).length">{{instance.exchange.currency_from || instance.exchange.currency_to || instance.exchange.currency}}</template>
                      <template v-else>{{((instance || {}).document_type || '') | firstDocumentsTypeCurrency}}</template>
                    </v-list-item-subtitle>
                  </v-list-item>
                </v-list>
              </v-col>
              <!-- end totales -->
            </v-card-text>
          </v-card>
        </div>
        </div>
        <!-- detail card -->

        <!-- panels -->
        <div class="pb-5" style="flex:1; min-width: 180px;">
           <!-- SII / SUNAT -->
           <v-card class="mt-1" flat>
            <v-toolbar color="transparent" flat height="46">
              <v-toolbar-title class="px-5 subtitle-2 secondary--text font-weight-semibold">
                <v-row align="center" no-gutters>
                  <img class="ml-n1 mr-2" :src="require(`@/assets/ax/sii--icon.svg`)" />
                  Servicio de impuestos internos
                </v-row>
              </v-toolbar-title>
            </v-toolbar>
            <v-divider />
            <v-card-text class="pa-0">
              <div class="pt-4 px-5 pb-3">
                <v-list class="v-list-form-status transparent pa-0">
                  <v-list-item class="pa-0">
                    <v-list-item-title class="fontBody--text subtitle-2 font-weight-bold"><v-icon class="mr-3" size="20" left color="primary">mdi-clipboard</v-icon>Estado</v-list-item-title>
                    <v-list-item-subtitle class="body-1">
                      <v-btn v-if="!instance.status || ((instance || {}).status || {}).code === 'IMP'" @click="taxAgencyService('documents', 'documents', 'irs_status')" :loading="taxAgencyLoader" text color="primary" :ripple="false">Actualizar estado</v-btn>
                      <span v-else-if="((instance || {}).status || {}).id === 'PDR'" class="body-2 text--disabled d-block" >Sin estado</span>
                      <chip-status v-else :status="((instance || {}).status || {}).code" :showSummary="true" :isTooltip="false" />
                    </v-list-item-subtitle>
                  </v-list-item>
                  <v-list-item class="pa-0 mb-2 mt-4" v-if="((instance || {}).status || {}).detail">
                    <v-list-item-title class="fontBody--text subtitle-2 font-weight-bold align-self-start" style="white-space: normal !important"><v-icon class="mr-3" size="20" left color="primary">mdi-clipboard-search</v-icon>Detalle del estado</v-list-item-title>
                    <v-list-item-subtitle class="body-1 align-self-start" style="white-space: normal !important">
                      <!-- <v-tooltip color="bgSearch" right transition="scale-transition" max-width="300">
                        <template v-slot:activator="{on}"> -->
                          <!-- <v-btn class="fontDraw--text" text v-on="on" :ripple="false" style="justify-content: left;">
                            <span class="d-inline-block">{{instance.status.detail}}</span>
                          </v-btn>
                        </template> -->
                        <span class="">{{instance.status.detail}}</span>
                      <!-- </v-tooltip> -->
                    </v-list-item-subtitle>
                  </v-list-item>
                  <v-list-item class="pa-0">
                    <v-list-item-title class="fontBody--text subtitle-2 font-weight-bold"><v-icon class="mr-3" size="20" left color="primary">mdi-clipboard-list</v-icon>Track ID</v-list-item-title>
                    <v-list-item-subtitle class="body-1">
                      <v-tooltip v-if="((instance || {}).status || {}).irs_id" color="bgSearch" right transition="scale-transition" max-width="300">
                        <template v-slot:activator="{on}">
                          <v-btn class="fontDraw--text" @click="toClipboard(((instance || {}).status || {}).irs_id)" text v-on="on" :ripple="false" style="justify-content: left;">{{((instance || {}).status || {}).irs_id}}</v-btn>
                        </template>
                        <span class="d-block px-3 py-2">Copiar</span>
                      </v-tooltip>
                      <span v-else-if="(((instance || {}).status || {}).id !== 'PDR')"><v-btn @click="taxAgencyService('documents', 'documents', 'irs_send')" :loading="taxAgencyLoader" text color="primary" :ripple="false">Enviar a la agencia fiscal</v-btn></span>
                    </v-list-item-subtitle>
                  </v-list-item>
                </v-list>
              </div>
            </v-card-text>
          </v-card>
          <!-- end SII / SUNAT -->

          <!-- business response -->
          <v-card class="mt-5" flat v-if="!!Object.keys(customerResponse)?.length">
            <v-toolbar color="transparent" flat height="46">
              <v-toolbar-title class="px-5 subtitle-2 secondary--text font-weight-semibold">
                <v-row align="center" no-gutters>
                  <v-icon size="18" left color="secondary">mdi-domain</v-icon>
                  Respuesta comercial
                </v-row>
              </v-toolbar-title>
            </v-toolbar>
            <v-divider />
            <v-card-text class="pa-0">
              <div class="pt-4 px-5 pb-3">
                <v-list class="v-list-form-status transparent pa-0">
                  <v-list-item class="pa-0">
                    <v-list-item-title class="fontBody--text subtitle-2 font-weight-bold d-flex align-center"><v-icon class="mr-3" size="20" left color="primary">mdi-clipboard</v-icon>Estado</v-list-item-title>
                    <v-list-item-subtitle class="body-1">
                      <chip-status :status="customerResponse.code" :showDetail="true" type="purchases" :isTooltip="true" />
                    </v-list-item-subtitle>
                  </v-list-item>
                  <v-list-item class="pa-0">
                    <v-list-item-title class="fontBody--text subtitle-2 font-weight-bold d-flex align-center"><v-icon class="mr-3" size="20" left color="primary">mdi-clipboard-list</v-icon>Código</v-list-item-title>
                    <v-list-item-subtitle class="body-1">
                      <chip-status :status="customerResponse.code" :showCode="true" type="purchases" :isTooltip="false" />
                    </v-list-item-subtitle>
                  </v-list-item>
                  <v-list-item class="pa-0">
                    <v-list-item-title class="fontBody--text subtitle-2 font-weight-bold d-flex align-center"><v-icon class="mr-3" size="20" left color="primary">mdi-clipboard-search</v-icon>Descripción</v-list-item-title>
                    <v-list-item-subtitle class="body-1">
                      <!-- <v-tooltip color="bgSearch" right transition="scale-transition" max-width="300">
                        <template v-slot:activator="{on}"> -->
                          <!-- <v-btn class="fontDraw--text" text v-on="on" :ripple="false" style="justify-content: left;">
                            <span class="d-inline-block">{{instance.status.detail}}</span>
                          </v-btn>
                        </template> -->
                        <span v-if="customerResponse.detail">{{customerResponse.detail}}</span>
                        <span class="body-2 text--disabled font-italic" v-else>Sin especificar</span>
                      <!-- </v-tooltip> -->
                    </v-list-item-subtitle>
                  </v-list-item>
                  <v-list-item class="pa-0 my-2">
                    <v-list-item-title class="fontBody--text subtitle-2 font-weight-bold d-flex align-center" style="white-space: normal !important"><v-icon class="mr-3" size="20" left color="primary">mdi-account</v-icon>Responsable</v-list-item-title>
                    <v-list-item-subtitle>
                      <span class="body-1" v-if="customerResponse.responsable" style="white-space: normal !important">{{customerResponse.responsable}}</span>
                      <span class="body-2 text--disabled font-italic" v-else>Sin especificar</span>
                    </v-list-item-subtitle>
                  </v-list-item>
                  <v-list-item class="pa-0 mt-2">
                    <v-list-item-title class="fontBody--text subtitle-2 font-weight-bold d-flex align-center" style="white-space: normal !important"><v-icon class="mr-3" size="20" left color="primary">mdi-calendar-blank</v-icon>Fecha</v-list-item-title>
                    <v-list-item-subtitle class="body-1">
                      <span v-if="customerResponse.date">{{customerResponse.date | dateTimeSecond}}</span>
                      <span class="body-2 text--disabled font-italic" v-else>Sin especificar</span>
                    </v-list-item-subtitle>
                  </v-list-item>
                </v-list>
              </div>
            </v-card-text>
          </v-card>
          <!-- end business response -->

          <!-- events -->
          <v-card flat class="mt-5">
            <v-toolbar color="transparent" flat height="46">
              <v-toolbar-title class="px-5 subtitle-2 secondary--text font-weight-semibold"><v-icon size="18" left color="secondary">mdi-timeline-clock</v-icon>Eventos</v-toolbar-title>
            </v-toolbar>
            <v-skeleton-loader v-if="eventLoading" class="mb-2 panel px-1 rounded-md" transition="fade-transition" type="list" />
            <template v-else-if="eventsList.length ">
              <v-divider />
              <v-card-text class="pa-0">
                <v-list class="transparent px-0 py-2">
                  <div v-for="(d, i) in events" :key="i">
                    <v-list-item :class="['px-5 py-0', {'mb-n2' : i !== events.length - 1}]" :key="i">
                      <v-list-item-icon>
                        <v-icon color="primary" v-if="d.name === 'sent_email'">mdi-email-arrow-right</v-icon>
                        <v-icon color="primary" v-else-if="d.name === 'sent_irs'">mdi-bank</v-icon>
                        <v-icon color="primary" v-else-if="d.name === 'rcv_th_pos'">mdi-printer-pos</v-icon>
                        <v-icon color="primary" v-else-if="['sent_agent', 'sent_final'].includes(d.name)">mdi-source-repository</v-icon>
                        <v-icon color="primary" v-else-if="['rcv_th_js', 'rcv_th_ws'].includes(d.name)">mdi-cloud-braces</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title class="ml-n5">
                        <span class="d-block secondary--text body-1">
                          <span class="font-weight-bold" v-if="d.name === 'sent_email'">Mail
                            <span class="text-lowercase" v-if="d.detail.search('Enviado') === -1">{{ $t(`generals.${d.detail.split('to ')[0]}`) }}</span>
                            <span class="text-lowercase" v-else>{{ d.detail.split('a ')[0] }}</span>
                          </span>
                          <span class="font-weight-bold" v-else-if="d.name === 'sent_irs'">Enviado a {{$t('generals.SII')}}</span>
                          <span class="font-weight-bold" v-else-if="d.name === 'rcv_th_pos'">Recibido en POS</span>
                          <span class="font-weight-bold" v-else-if="d.name === 'sent_agent'">Enviado al agente</span>
                          <span class="font-weight-bold" v-else-if="d.name === 'sent_final'">Enviado al agente con estado final</span>
                          <span class="font-weight-bold" v-else-if="d.name === 'rcv_th_js'">Recibido vía API-REST</span>
                          <span class="font-weight-bold" v-else-if="d.name === 'rcv_th_ws'">Recibido vía SOAP</span>
                        </span>
                        <span v-if="d.name !== 'sent_email'" class="d-block body-2 fontDraw--text">{{d.created | dateTime}}</span>
                        <v-tooltip v-if="d.name === 'sent_email'" :open-on-hover="d.detail.length > 120" color="bgSearch" bottom :nudge-right="30" transition="scale-transition" max-width="300">
                          <template v-slot:activator="{on}">
                            <div class="pr-1 row no-gutters" v-on="on">
                              <div class="col-11 text-truncate">
                                <span class="mr-1">{{d.detail.split(d.detail.search('Enviado') === -1 ? 'to ' : 'a ')[1].replace(/[[\]']/g, '') }}</span>
                              </div>
                            </div>
                          </template>
                          <span v-if="d.detail.length > 120" class="d-block px-3 py-2">{{d.detail.split(d.detail.search('Enviado') === -1 ? 'to ' : 'a ')[1].replace(/[[\]']/g, '')}}</span>
                        </v-tooltip>
                       </v-list-item-title>
                    </v-list-item>
                    <v-sheet class="text-left ml-8 mt-n4 mb-n2" v-if="i !== eventsList.length - 1" height="18">
                      <v-icon size="3" color="placeholder" style="position: absolute; left: 30px">mdi-circle</v-icon>
                      <v-divider vertical class="my-0" />
                      <v-icon class="mb-n1" size="3" color="placeholder" style="left: -1px; top: 7px">mdi-circle</v-icon>
                    </v-sheet>
                  </div>
                  <template v-if="eventsList.length > 3">
                    <v-divider class="mt-4" />
                    <v-btn class="mt-1 mb-n1 mx-5" color="primary" @click="showMoreEvents=!showMoreEvents" text>
                      {{ !showMoreEvents ? 'Ver todos los eventos' : 'Ver menos eventos' }} </v-btn>
                  </template>
                </v-list>
              </v-card-text>
            </template>
          </v-card>
          <!-- end events -->

          <!-- related documents -->
          <v-expansion-panels class="mt-5" v-model="panelRelatedDocuments">
            <v-expansion-panel>
              <v-expansion-panel-header class="px-4 py-3">
                <v-row no-gutters align="center" justify="space-between">
                  <v-col>
                    <span class="subtitle-2 secondary--text font-weight-bold"><v-icon size="18" left color="secondary">mdi-file-document-multiple</v-icon>Documentos relacionados</span>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="px-0">
                <v-divider />
                <v-empty-state class="mt-n1 pb-2" v-if="!loading && !documentsList.length" type="documents" id="documentos" customClass="mt-4" :isFree="true" title="Aún no tienes documentos relacionados" :height="199" />
                <v-sheet class="text-center pa-0" rounded v-else>
                  <documents-list :loading="loading" :show-pagination="false" />
                  <template v-if="count">
                    <v-divider />
                    <v-row class="px-4 py-4" align="center">
                      <v-btn class="ml-5 body-1" :to="{name: 'DocumentsList', query: { documents_related: instance.id, name: instance.name || instance.id } }" :ripple="false" text color="primary">Ver todos los documentos</v-btn>
                    </v-row>
                  </template>
                </v-sheet>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <!-- end related documents -->

          <!-- comments --
          <v-expansion-panels class="mt-5" v-model="panelComments">
            <v-expansion-panel>
              <v-expansion-panel-header class="px-4 py-3">
                <v-row no-gutters align="center" justify="space-between">
                  <v-col>
                    <span class="px-5 subtitle-2 fontBody--text font-weight-bold">Comentarios</span>
                  </v-col>
                  <v-col class="text-right mr-2">
                    <v-icon size="25" left :color="comments.length ? 'primary' : 'secondary'">mdi-message-badge</v-icon>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="px-0">
                <v-container>
                  <v-row class="px-5 pb-5" align="start">
                    <img v-if="env === 'ax'" :src="require(`@/assets/user-${ $vuetify.theme.dark ? 'dark' : 'light'}.svg`)" :width="30" />
                    <v-icon v-else large color="defaultGrey">mdi-account-circle</v-icon>
                    <v-col class="py-0 pr-0">
                      <v-textarea class="textarea-email" placeholder="Escribe un comentario..." auto-grow outlined flat hide-details>
                        <template v-slot:append>
                          <v-btn text :ripple="false"><v-icon color="primary">mdi-send</v-icon></v-btn>
                        </template>
                      </v-textarea>
                    </v-col>
                  </v-row>
                 <v-list three-line v-if="comments.length">
                  <v-list-item>
                    <v-list-item-avatar>
                      <img :src="require(`@/assets/user-${ $vuetify.theme.dark ? 'dark' : 'light'}.svg`)" :width="30" />
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title class="subtitle-1 font-weight-bold secondary--text">Juan Pérez</v-list-item-title>
                      <span class="d-block secondary--text caption">Hace 3 horas</span>
                      <v-list-item-subtitle class="secondary--text mt-1">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fames libero integer potenti at in nec. Urna turpis massa est ultrices faucibus diam et donec vitae.
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                 </v-list>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <!- end comments -->
        </div>
        <!-- end panels -->
      </div>
    </v-col>
    <!-- dialog grant -->
    <v-dialog v-model="dialogGrant" width="600" persistent no-click-animation scrollable overlay-color="overlay">
      <v-card class="pa-0">
        <v-toolbar flat height="46" class="transparent">
          <v-toolbar-title class="px-5 subtitle-2 fontBody--text font-weight-semibold">Ceder documento</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-row align="center" justify="center" class="mr-3">
              <v-btn @click="dialogGrant=false" icon small retain-focus-on-click><v-icon color="fontBody" size="20">mdi-close</v-icon></v-btn>
            </v-row>
          </v-toolbar-items>
        </v-toolbar>
        <v-divider />
        <v-card-text class="px-0 py-4">
          <v-row no-gutters>
            <v-col class="pa-0">
              <v-list class="v-list-form-extended transparent pa-0">
                <v-list-item class="px-5 pt-0">
                  <v-list-item-title>
                    <span class="font-weight-bold">Cesionario</span>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item class="px-5">
                  <v-list-item-title>{{$t('generals.RUT')}}</v-list-item-title>
                  <v-list-item-subtitle>
                    <v-col cols="6">
                      <v-text-field v-model="$v.grant.tax_id.$model" :error="$v.grant.tax_id.$error" v-dni="'CL'" outlined hide-details required single-line dense maxlength="32" />
                    </v-col>
                  </v-list-item-subtitle>
                </v-list-item>
                <v-list-item class="py-2 px-5">
                  <v-list-item-title>Nombre</v-list-item-title>
                  <v-list-item-subtitle>
                    <v-text-field v-model="$v.grant.name.$model" :error="$v.grant.name.$error" outlined hide-details required single-line dense maxlength="32" />
                  </v-list-item-subtitle>
                </v-list-item>
                <v-list-item class="px-5">
                  <v-list-item-title>Email</v-list-item-title>
                  <v-list-item-subtitle>
                    <v-text-field v-model="$v.grant.email.$model" :error="$v.grant.email.$error" outlined hide-details required single-line dense maxlength="56" />
                  </v-list-item-subtitle>
                </v-list-item>
                <v-list-item class="py-2 px-5">
                  <v-list-item-title>Dirección</v-list-item-title>
                  <v-list-item-subtitle>
                    <v-text-field v-model="$v.grant.address.$model" :error="$v.grant.address.$error" outlined hide-details required single-line dense maxlength="36" />
                  </v-list-item-subtitle>
                </v-list-item>
                <v-divider class="my-3" />
                <v-list-item class="px-5">
                  <v-list-item-title>
                    <span class="font-weight-bold">Cedente</span>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item class="pt-2 pb-1 px-5">
                  <v-list-item-title>Email</v-list-item-title>
                  <v-list-item-subtitle>
                    <v-text-field v-model="$v.grant.assignor_email.$model" :error="$v.grant.assignor_email.$error" outlined hide-details required single-line dense maxlength="36" />
                  </v-list-item-subtitle>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions class="px-5 py-2">
          <v-spacer />
          <v-btn @click="dialogGrant=false" outlined>Cancelar</v-btn>
          <v-btn @click="createGrant" :loading="$store.state.irs.createLoader" color="primary">Ceder documento</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog grant -->

    <!-- dialog confirm markAsAnulled -->
    <v-dialog v-model="dialogMarkAsAnulled" width="792" persistent no-click-animation overlay-color="overlay">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar flat height="46" class="transparent">
            <v-toolbar-title class="px-5 subtitle-2 fontBody--secondary font-weight-semibold">Marcar como nulo</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="dialogMarkAsAnulled=false" icon small retain-focus-on-click><v-icon color="fontBody" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="py-4 px-5 fontBody--text">
          <v-row class="pt-2 pb-4" align="center">
            <v-col cols="3">
              <img :src="require(`@/assets/backgrounds/modal-document-null.svg`)" :width="148" />
            </v-col>
            <v-col class="pr-4 pl-4">
              <div class="mt-1 mb-3">
                <v-icon class="mr-2" color="secondary" size="14">mdi-file-document</v-icon><span class="body-2 font-weight-medium text-uppercase">{{((instance || {}).document_type || '') | nameDocumentsType}} Nº{{number}}</span>
              </div>
              <span class="d-block body-1 pt-2">Al marcar como nulo un documento, sólo lo estás anulando en la plataforma y no en {{$t('generals.SII')}}.</span>
              <span class="d-block body-1 mt-4 mb-3">Escribe <span class="font-weight-bold">“confirmar”</span> para continuar.</span>
              <v-text-field v-model="confirm" outlined single-line maxlength="9" dense hide-details />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-2 px-5">
          <v-spacer/>
          <v-btn @click="dialogMarkAsAnulled=false" outlined>Cancelar</v-btn>
          <v-btn color="primary" @click="markAsAnulled" :loading="loadingAnulled" :disabled="confirm.toLowerCase() !== 'confirmar' ">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog confirm markAsAnulled -->

    <!-- dialog create clone document -->
    <v-dialog v-model="createDocumentDialog" width="540" persistent no-click-animation overlay-color="overlay">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar flat height="46" class="transparent">
            <v-toolbar-title class="px-5 subtitle-2 fontBody--secondary font-weight-semibold">Crear documento</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="createDocumentDialog=false" icon small retain-focus-on-click><v-icon color="fontBody" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="px-5 py-4">
          <v-row class="pt-2" align="center">
            <v-col cols="4">
              <img :src="require(`@/assets/backgrounds/modal-document-clone.svg`)" :width="148" />
            </v-col>
            <v-col class="pr-4 pl-2">
              <v-list class="v-list-form pa-0 transparent">
                <v-list-item class="pa-0 mb-4">
                  <v-list-item-content>
                    <v-list-item-title class="fontBody--text body-1 font-weight-semibold">Crear {{ ((DocumentTypesList.find(d => d.id === cloneDoc) || {}).name || '').toLowerCase() }}</v-list-item-title>
                    <v-list-item-subtitle class="mt-1 mb-16">
                      <v-radio-group class="ma-0" v-model="actionCreateDoc" column absolute style="position:absolute" dense hide-details>
                        <v-radio label="Anula documento" value="1" class="mb-0"></v-radio>
                        <v-radio label="Corrige texto documento" value="2" class="mb-0"></v-radio>
                        <v-radio label="Corrige monto documento" value="3"></v-radio>
                      </v-radio-group>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-2 px-5">
          <v-spacer/>
          <v-btn outlined @click="createDocumentDialog=false" :ripple="false">Cancelar</v-btn>
          <v-btn color="primary" :to="{ name: 'DocumentsClone', params: { id: instance.id }, query: { document_type: cloneDoc, action: actionCreateDoc, ...(['CL39', 'CL34'].includes(instance.document_type)  && { origin: instance.document_type}) } }" :ripple="false">Crear documento</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog create clone document -->

    <!-- dialog pdf -->
    <v-dialog v-model="modalPDF" width="700" persistent :scrollable="true" no-click-animation overlay-color="overlay">
      <render-pdf :documentType="((instance || {}).document_type || '') | nameDocumentsType" :number="number" @close="modalPDF=false" :loaderRender="loaderRender" :renderUrl="renderUrl" :flat="true" :formatPDF.sync="formatPDF" :showFormat="true"  />
    </v-dialog>
    <!-- end dialog pdf -->

    <!-- email -->
    <v-dialog v-model="modalEmail" width="700" persistent :scrollable="true" no-click-animation overlay-color="overlay">
      <v-card min-height="300px">
        <v-card-title class="pa-0">
          <v-toolbar flat height="46" class="transparent">
            <v-toolbar-title class="px-5 subtitle-2 fontBody--text font-weight-semibold">Enviar documento por mail</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="modalEmail=false" icon small retain-focus-on-click><v-icon color="fontBody" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="pa-0" >
          <v-col class="pa-0">
            <v-card class="transparent rounded-0 pa-0" flat>
              <v-card-subtitle class="background pt-5 pb-3 px-5">
                <div class="d-flex align-center">
                  <span class="fontBody--text body-1" style="min-width: 60px;">Para</span>
                  <v-combobox class="a-combobox" v-model="$v.messages.recipient.$model" :error="$v.messages.recipient.$error" hide-details :items="[]" outlined required hide-selected :placeholder="!messages.recipient ? 'Escribe el email del destinatario' : ''" @change="setEmails" multiple hide-no-data deletable-chips small-chips single-line dense autocomplete="off">
                    <template v-slot:selection="data">
                      <v-chip class="pr-2 ma-1" small v-bind="data.attrs" :input-value="data.selected" close @click="data.select" @click:close="remove(data.item)">{{ data.item }}</v-chip>
                    </template>
                  </v-combobox>
                </div>
                <div class="d-flex align-center mt-3">
                  <span class="fontBody--text body-1" style="min-width: 60px;">Asunto</span>
                  <v-text-field v-model="$v.messages.subject.$model" :error="$v.messages.subject.$error" outlined required single-line dense maxlength="60" hint="Escribe un asunto para el mensaje" persistent-hint :hide-details="!$v.messages.subject.$error" />
                </div>
                <v-col class="pa-0 mt-2">
                  <span class="fontBody--text body-1 mr-5">Archivos adjuntos</span>
                  <v-chip class="ma-1 pl-1 pr-2" color="defaultGrey" small outlined label>
                    <v-checkbox class="ma-0 pb-1" v-model="messages.type" value="pdf" color="primary" dense hide-details />
                    <v-icon left size="14">mdi-paperclip</v-icon>{{$store.state?.auth?.account?.country === 'PE' ? number.replace(/[ ]/g, '') : number}}.pdf
                  </v-chip>
                  <v-chip class="ma-1 pl-1 pr-2" color="defaultGrey" small outlined label>
                    <v-checkbox class="ma-0 pb-1" v-model="messages.type" value="xml" dense hide-details />
                    <v-icon left size="14">mdi-paperclip</v-icon>{{$store.state?.auth?.account?.country === 'PE' ? number.replace(/[ ]/g, '') : number}}.xml
                  </v-chip>
                </v-col>
              </v-card-subtitle>
              <v-divider />
              <v-card-text class="px-0 mt-2 mx-auto bgPanel overflow-y-auto text-center" style="max-height: 450px;">
                <v-alert border="top" class="pa-0 mx-auto" color="var(--light-blue-primary)" colored-border elevation="0" :style="`width: ${$vuetify.breakpoint.width < 1270 ? 465 : 564}px;`" />
                  <v-sheet class="mx-auto" :width="$vuetify.breakpoint.width < 1270 ? 470 : 570" style="border-radius: 10px; margin-top: -17px;" outlined>
                    <reminder-documents-email :instance="instance" :activeDocument="activeDocument" :color-background="account.color_background" :message.sync="messages.text" />
                  </v-sheet>
              </v-card-text>
            </v-card>
          </v-col>
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-4 px-5">
          <v-spacer />
          <v-btn @click="dialogMessages=false" outlined>Cancelar</v-btn>
          <v-btn @click="createMessages" :loading="$store.state.messages.createLoader" color="primary">Enviar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end email -->
  </v-row>
</template>

<script>
  import { mapState } from 'vuex'
  import MiniHeader from '@/components/commons/MiniHeader'
  import { email, minLength, maxLength, required } from 'vuelidate/lib/validators'
  import { rutValidator } from 'vue-dni'
  import CreditNoteCreate from '@/modules/documents/components/CreditNoteCreate'
  import ChipStatus from '@/components/commons/ChipStatus'
  import VModal from '@/components/commons/VPanel'
  import SkeletonDetail from '@/components/commons/skeletonLoader/SkeletonDetail'
  import RenderPdf from '@/components/files/RenderPdf'
  import ReminderDocumentsEmail from '@/components/reminders/documents/Email'
  import DocumentsList from '@/modules/documents/components/DocumentsList'
  import GenericViewMixin from '@/mixins/GenericViewMixin'
  import RenderViewMixin from '@/mixins/RenderViewMixin'
  import TaxAgencyViewMixin from '@/mixins/TaxAgencyViewMixin'
  import DocumentTypes from '@/collections/documentTypes'
  import numeral from 'numeral'
  import moment from 'moment'
  import VEmptyState from '@/components/commons/VEmptyState'
  import cloneDeep from 'lodash/cloneDeep'

  export default {
    mixins: [
      GenericViewMixin,
      RenderViewMixin,
      TaxAgencyViewMixin
    ],
    components: {
      VEmptyState,
      ChipStatus,
      VModal,
      SkeletonDetail,
      RenderPdf,
      CreditNoteCreate,
      DocumentsList,
      MiniHeader,
      ReminderDocumentsEmail
    },
    data: () => ({
      eventLoading: false,
      // events: [],
      showMoreEvents: false,
      confirm: '',
      dialogMarkAsAnulled: false,
      loadingAnulled: false,
      actionCreateDoc: '',
      cloneDoc: '',
      createDocumentDialog: false,
      formatPDF: 1,
      loadingDetail: false,
      loading: false,
      scroll: 0,
      activePanel: false,
      grant: {
        tax_id: '',
        name: '',
        address: '',
        email: '',
        assignor_email: ''
      },
      dialogGrant: false,
      dialogCreditNote: false,
      dialogMessages: false,
      loadingDocumentsDuplicate: false,
      panelIrsSii: [],
      panelRelatedDocuments: false,
      panelTotals: false,
      modalEmail: false,
      panelComments: false,
      modalPDF: false,
      comments: '',
      messages: {
        text: '',
        recipient: '',
        subject: '',
        type: ['pdf', 'xml']
      },
      env: process.env?.VUE_APP_MODE ?? 'fc',
      documentsCanBeCreatedList: {
        CL33: ['CL61', 'CL56'],
        CL61: ['CL56'],
        CL39: ['CL61', 'CL56'],
        CL110: ['CL112', 'CL111'],
        CL111: ['CL112'],
        CL34: ['CL61', 'CL56'],
        CL41: ['CL61', 'CL56'],
        CL112: ['CL111'],
        CL56: ['CL61'],
        CL46: ['CL61', 'CL56']
      },
      DocumentTypesList: DocumentTypes,
      breadcrumbs: {
        main: 'Emisión',
        children: [
          {
            text: 'Documentos',
            route: 'DocumentsList'
          }
        ]
      },
      customerResponse: {}
    }),
    computed: {
      ...mapState({
        instance: state => state.documents.documentsDetail,
        supplierresponsesList: state => state.irs.supplierresponsesList,
        creditnotesList: state => state.documents.creditnotesList,
        debitnotesList: state => state.documents.debitnotesList,
        eventsList: state => state.documents.eventsList,
        contactsList: state => state.customers.contactsList,
        count: state => state.documents.documentsCount,
        documentsList: state => state.documents.documentsList
      }),
      totalCredits () {
        return this.sumProperty(this.creditnotesList, 'total')
      },
      totalDebits () {
        return this.sumProperty(this.debitnotesList, 'total')
      },
      totalAdjustedDocuments () {
        return this.instance.total - this.totalCredits + this.totalDebits
      },
      totalPaid () {
        return this.instance.payments.length ? this.instance.payments.map(c => c.amount_paid).reduce((a, b) => a + b) : 0
      },
      activeDocument () {
        return DocumentTypes.find((d) => d.id === this.instance.document_type)
      },
      number () {
        return this.$store.state?.auth?.account?.country === 'PE' ? `${this.instance.prefix} - ${this.instance.number}` : this.instance.number
      },
      documentsCanBeCreated () {
        let array = this.documentsCanBeCreatedList[this.instance.document_type]
        if (!this.$store.state.accounts.me?.is_owner && !this.$store.state.auth.userAccount.is_staff) {
          /* eslint-disable */
          const ownGroupPermissions = [...(this.$store.state.accounts.me?.document_type_permissions ?? []), ...(this.$store.state.accounts.me?.groups?.map(({ document_type_permissions }) => document_type_permissions)[0] ?? [])]
          return array?.filter((item) => ownGroupPermissions.find((elm) => (elm.permission === 'add_document' && elm.document_type === item))) ?? []
          }
          return array
      },
      checkCreatePermissionDTE () {
        return (dte) => {
          if (!this.$store.state.accounts.me?.is_owner && !this.$store.state.auth.userAccount.is_staff) {
            /* eslint-disable */
            const ownGroupPermissions = [...(this.$store.state.accounts.me?.document_type_permissions ?? []), ...(this.$store.state.accounts.me?.groups?.map(({ document_type_permissions }) => document_type_permissions)[0] ?? [])]
            return ownGroupPermissions.filter(({ permission, document_type }) => permission === 'add_document' && document_type === dte).length
          }
        return true
        }
      },
      events () {
        return !this.showMoreEvents ? this.eventsList.slice(0,3) : this.eventsList
      }
    },
    watch: {
      panelRelatedDocuments (val) {
        if (val === 0) this.getList()
      },
      modalPDF (val) {
        this.modalRender = val
        if (!this.renderUrl) this.renderDocument('documents')
      
        this.formatPDF = 1
      },
      formatPDF: {
        handler (val) {
          if (val === 1) this.renderDocument('documents')
          else {
            this.renderDocument('documents', 'documents', 'pdf', this.instance.id, { thermal_format: val})
          }
        },
        inmediate: true,
        deep: true
      },
      errorPDF: {
        handler (val) {
          this.renderDocument('documents', 'documents', 'pdf', this.instance.id, { thermal_format: 110})
        },
        inmediate: true,
        deep: true
      },
      // showMoreEvents () {
      //   this.eventsList
      // }
    },
    created () {
      this.activePanel = true
      this.retrieve()
    },
    methods: {
      onScroll (e) {
        this.scroll = e.target?.scrollingElement?.scrollTop ?? 0
      },
      markAsAnulled () {
        this.loadingAnulled = true
        this.$store.dispatch('documents/GET', {
          resource: `documents/${this.$route.params.id}/mark_as_anulled/`
        })
        .then(() => {
          this.retrieve()
        })
        .catch((error) => {
          this.$dialog.message.error(this.parseErrors(error.response.data))
        })
        .finally(() => {
          this.loadingAnulled = false
          this.dialogMarkAsAnulled = false
        })
      },
      handlerClone (doc = '') {
        this.createDocumentDialog = true
        this.cloneDoc = doc
      },
      setEmails (e) {
        let array = []
        e.forEach(item => {
          let elm = item.replace(/ /g, ',')
          array = [...array, ...elm.split(',')]
        })
        const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
        this.messages.recipient = array.filter(item => regex.test(item))
      },
      async getList () {
        this.loading = true
        await this.$store.dispatch('documents/LIST', {
          resource: 'documents',
          query: {
            documents_related: this.instance.id,
            page_size: 6
          }
        })
        this.loading = false
      },
      retrieve () {
        this.loadingDetail = true
        this.$store.dispatch('documents/RETRIEVE', {
          resource: 'documents',
          id: this.$route.params.id
        })
        .then(() => {
          this.$store.dispatch('documents/RETRIEVE', {
            resource: 'documents',
            id: this.instance.id,
            detail: 'customer_response',
            setDetail: false
          })
          .then((response) => {
            this.customerResponse = response.data
          })
          this.eventLoading = true
          this.$store.dispatch('documents/LIST', {
            resource: 'events',
            query: {
              document: this.instance.id
            }
          })
          this.messages.subject = `Envía ${this.activeDocument?.name ?? ''} N° ${this.number}`
          this.messages.text = `Estimado cliente, ${this.instance?.customer?.name ?? ''},\n\n`
          this.messages.text += `Adjunto ${this.activeDocument?.name ?? ''} N° ${this.number}, `
          this.messages.text += `correspondiente al ${moment(this.instance?.date).format('DD MMM YYYY')} `
          this.messages.text += `por un monto de ${numeral(this.instance?.total).format('0,0')} ${this.instance?.exchange?.currency_from ?? this.instance?.exchange?.currency_to ?? this.activeDocument?.currencies[0] ?? ''} \n\nAtentamente ${this.account?.name}`
        })
        .finally(() => {
          this.eventLoading = false
          this.loadingDetail = false
        })
      },
      createCreditNote () {
        this.$v.creditNote.$touch()
        if (this.$v.creditNote.$invalid) {
          return false
        }
        this.$store.dispatch('documents/CREATE', {
          resource: 'creditnotes',
          payload: {
            documents: this.instance.id,
            note: this.creditNote.note
          }
        })
        .then(() => {
          this.dialogCreditNote = false
          this.retrieve()
        })
        .catch((error) => {
          this.$dialog.message.error(this.parseErrors(error.response.data))
        })
      },
      createMessages () {
        this.messages.document = this.instance.id
        this.messages.type = this.messages.type.length === 2 ? 'all' : this.messages.type.toString()

        this.$v.messages.$touch()
        if (this.$v.messages.$invalid) {
          return false
        }
        this.$store.dispatch('messages/CREATE', {
          resource: 'messages',
          payload: this.messages
        })
        .then(() => {
          this.dialogMessages = false
          this.$dialog.message.info('Email enviado con éxito')
          this.messages.recipient = ''
          this.messages.type = []
          this.messages.text = `Estimado cliente, ${this.instance?.customer?.name ?? ''},\n\n`
          this.messages.text += `Adjunto ${this.activeDocument?.name ?? ''} N° ${this.number}, `
          this.messages.text += `correspondiente al ${moment(this.instance?.date).format('DD MMM YYYY')} `
          this.messages.text += `por un monto de ${numeral(this.instance?.total).format('0,0')} ${this.instance?.exchange?.currency_from ?? this.instance?.exchange?.currency_to ?? this.activeDocument?.currencies[0] ?? ''} \n\nAtentamente ${this.account?.name}`
          this.$v.messages.$reset()
        })
        .catch((error) => {
          this.$dialog.message.error(this.parseErrors(error.response.data))
        })
      },
      remove (item) {
        const index = this.messages.recipient.indexOf(item)
        if (index >= 0) {
          this.messages.recipient.splice(index, 1)
        }
      },
      createGrant () {
        this.$v.grant.$touch()
        if (this.$v.grant.$invalid) {
          return false
        }
        this.grant.documents = this.instance.document
        this.$store.dispatch('irs/CREATE', {
          resource: 'irs/sii/grants',
          payload: { ...this.grant, ...{document: this.$route.params.id} }
        })
        .then((response) => {
          this.grant = response.data
          this.dialogGrant = false
        })
        .catch((error) => {
          this.$dialog.message.error(this.parseErrors(error.response.data))
        })
      },
      cloneDocuments () {
        const dueDate = moment(this.today).add(30, 'days').format('YYYY-MM-DD')
        const payload = cloneDeep(this.instance)
        delete payload.id
        delete payload.imported
        delete payload.local_file
        delete payload.metadata
        delete payload.number
        delete payload.status
        delete payload.subscription
        delete payload.irs
        delete payload.test_mode
        delete payload.created
        delete payload.updated
        payload.collection_method = 'deferred'
        payload.customer = this.instance.customer.id
        payload.date = this.today
        payload.document = this.instance.document.id
        payload.due_date = dueDate
        payload.lines.map((line) => {
          Object.keys(line).forEach((key) => {
            if (['created', 'id', 'documents', 'updated', 'total', 'total_adjusted'].includes(key)) {
              delete line[key]
            }
            if (!line.discount) {
              line.discount = 0
              line.discount_scheme = '%'
            }
          })
          return line
        })
        payload.payments = [{
          amount: this.instance.total,
          date: dueDate,
          description: 'Pago de factura',
          currency: this.instance.document_type
        }]
        this.loadingDocumentsDuplicate = true
        this.$store.dispatch('documents/CREATE', {
          resource: 'documents',
          payload: payload
        })
        .then((response) => {
          this.$router.push(`/documents/${response.data.id}/edit`)
        })
        .catch((error) => {
          this.$dialog.message.error(this.parseErrors(error.response.data))
        })
        .finally(() => {
          this.loadingDocumentsDuplicate = false
        })
      },
      builtInSupllierResponses () {
        this.$store.dispatch('irs/CREATE', {
          resource: 'irs/sii/supplierresponses',
          payload: {
            documents: this.instance.id
          }
        })
        .catch((error) => {
          this.$dialog.message.error(this.parseErrors(error.response.data))
        })
      },
      updateCollectible () {
        let dialogTitle = 'Marcar como incobrable'
        let dialogMsg = 'Esta factura no será considerada como pendiente de pago, pero será posible pagarla si el cliente lo intenta.'
        let successMsg = 'La factura se marcó como incobrable'
        if (this.instance.status === 'uncollectible') {
          dialogTitle = 'Marcar como cobrable'
          dialogMsg = 'Esta factura volverá a ser considerada como pendiente de pago.'
          successMsg = 'La factura se marcó como cobrable'
        }
        this.$dialog.warning({
          title: dialogTitle,
          text: `
            <span class="d-block mb-4">${dialogMsg}</span>
            <span class="d-block">¿Estás seguro de continuar?</span>
          `,
          actions: [
            {
              color: 'red',
              text: 'Continuar',
              handle: () => {
                this.$store.dispatch('documents/RETRIEVE', {
                  resource: 'documents',
                  id: this.$route.params.id,
                  detail: 'uncollectible',
                  loader: false
                })
                .then(() => {
                  this.$dialog.message.info(successMsg)
                })
                .catch((error) => {
                  this.$dialog.message.error(this.parseErrors(error.response.data))
                })
              }
            }
          ]
        })
      }
    },
    validations: {
      messages: {
        recipient: {
          required
        },
        subject: {
          required,
          minLength: minLength(6),
          maxLength: maxLength(60)
        },
        text: {
          required
        }
      },
      grant: {
        tax_id: {
          required,
          maxLength: maxLength(32),
          rutValidator
        },
        name: {
          required,
          maxLength: maxLength(32)
        },
        address: {
          required,
          maxLength: maxLength(56)
        },
        email: {
          required,
          email,
          maxLength: maxLength(36)
        },
        assignor_email: {
          required,
          email,
          maxLength: maxLength(36)
        }
      }
    }
  }
</script>
<style>
.textarea-email.v-text-field--enclosed .v-input__append-inner {
  align-self: end;
  padding: 10px 0;
}
</style>